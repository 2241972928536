<template>
    <b-card>
        <b-row>
            <b-col cols="12">
                <div class="float-left">
                    <router-link :to="'/detail_influencer/'+$route.params.id">
                        Back
                    </router-link>
                </div>
                <div class="float-right">
                    <b-form-select v-model="selectedSorting" :options="optionsSorting"></b-form-select>
                </div>
            </b-col>
            <b-tabs class="w-100">
                <b-tab active>
                    <template #title>
                        <h5>Instagram</h5>
                    </template>

                    <b-container>
                        <div v-if="instagramReview.totalData > 0">
                            <CardReview v-for="(review,i) in instagramReview.data" :review="review" :key="i"/>

                            <b-col cols="12" v-if="instagramReview.totalPage > instagramReview.currentPage">
                                <div class="float-right">
                                    <a href="javascript:;" @click="nextPage('Instagram')">Lihat Lebih Banyak</a>
                                </div>
                            </b-col>
                        </div>
                        <p class="text-center" v-else-if="instagramReview.totalData == null">
                            Please Wait...
                        </p>
                        <p class="text-center" v-else>
                            Data tidak ditemukan
                        </p>
                    </b-container>
                </b-tab>
                <b-tab v-if="facebookReview.totalData > 0">
                    <template #title>
                        <h5>Facebook</h5>
                    </template>

                    <b-container>
                        <div v-if="facebookReview.totalData > 0">
                            <CardReview v-for="(review,i) in facebookReview.data" :review="review" :key="i"/>

                            <b-col cols="12" v-if="facebookReview.totalPage > facebookReview.currentPage">
                                <div class="float-right">
                                    <a href="javascript:;" @click="nextPage('Facebook')">Lihat Lebih Banyak</a>
                                </div>
                            </b-col>
                        </div>
                        <p class="text-center" v-else-if="facebookReview.totalData == null">
                            Please Wait...
                        </p>
                        <p class="text-center" v-else>
                            Data tidak ditemukan
                        </p>
                    </b-container>
                </b-tab>
                <b-tab>
                    <template #title>
                        <h5>Youtube</h5>
                    </template>

                    <b-container>
                        <div v-if="youtubeReview.totalData > 0">
                            <CardReview v-for="(review,i) in youtubeReview.data" :review="review" :key="i"/>

                            <b-col cols="12" v-if="youtubeReview.totalPage > youtubeReview.currentPage">
                                <div class="float-right">
                                    <a href="javascript:;" @click="nextPage('YouTube')">Lihat Lebih Banyak</a>
                                </div>
                            </b-col>
                        </div>
                        <p class="text-center" v-else-if="youtubeReview.totalData == null">
                            Please Wait...
                        </p>
                        <p class="text-center" v-else>
                            Data tidak ditemukan
                        </p>
                    </b-container>
                </b-tab>
            </b-tabs>
        </b-row>
    </b-card>
</template>

<script>
import {
    BContainer,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BFormRating,
    BAvatar,
    BFormSelect
} from 'bootstrap-vue'
import CardReview from './CardReview.vue'
import axios from '@axios'

export default {
    name: 'InfluencerReview',
    components: {
        BContainer,
        BRow,
        BCol,
        BTabs,
        BTab,
        BCard,
        BCardText,
        BFormRating,
        BAvatar,
        BFormSelect,

        CardReview
    },
    data() {
        return {
            value: 5,
            instagramCurrentPage: 1,
            instagramReview: {
                totalData: null
            },
            facebookCurrentPage: 1,
            facebookReview: {
                totalData: null
            },
            youtubeCurrentPage: 1,
            youtubeReview: {
                totalData: null
            },

            selectedSorting: 'rating.desc',
            optionsSorting: [
                { value: 'createdAt.desc', text: 'Terbaru' },
                { value: 'rating.desc', text: 'Rating Tertinggi' },
                { value: 'rating.asc', text: 'Rating Terendah' },
            ],

            currentPage: null,
            totalPage: null
        }
    },
    mounted() {
        let _ = this
        _.getReview('Instagram', 'rating.desc')
        _.getReview('Facebook', 'rating.desc')
        _.getReview('YouTube', 'rating.desc')
    },
    methods: {
        getReview(socmed, sort, toPage = 1) {
            let _ = this
            axios.get('/advertiser/influencers/'+_.$route.params.id+'/reviews/'+socmed+'?limit='+ parseInt(2) * parseInt(toPage) +'&sortBy=' + sort)
                .then(resp => {
                    let data = resp.data.data
                    if ( socmed == 'Instagram' ) {
                        _.instagramReview = data
                    } else if ( socmed == 'Facebook' ) {
                        _.facebookReview = data
                    } else if ( socmed == 'YouTube' ) {
                        _.youtubeReview = data
                    }
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        nextPage(socmed) {
            let _ = this
            if ( socmed == 'Instagram' ) {
                _.instagramCurrentPage = parseInt(_.instagramCurrentPage) + parseInt(1)
                _.getReview('Instagram', _.selectedSorting, _.instagramCurrentPage)
            }

            if ( socmed == 'Facebook' ) {
                _.facebookCurrentPage = parseInt(_.facebookCurrentPage) + parseInt(1)
                _.getReview('Facebook', _.selectedSorting, _.facebookCurrentPage)
            }

            if ( socmed == 'YouTube' ) {
                _.youtubeCurrentPage = parseInt(_.youtubeCurrentPage) + parseInt(1)
                _.getReview('YouTube', _.selectedSorting, _.youtubeCurrentPage)
            }
        }
    },
    watch: {
        selectedSorting: function(newValue, oldValue) {
            let _ = this
            if ( newValue ) {
                _.getReview('Instagram', newValue, _.instagramCurrentPage)
                _.getReview('Facebook', newValue, _.facebookCurrentPage)
                _.getReview('YouTube', newValue, _.youTubeCurrentPage)
            }
        }
    }
}
</script>